<template>
    <div>
        <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
        <div v-if="!isSpinner" class="chat_wrapper">
            <!-- <div v-if="chatArr.length &&  showStickyNotes !== 0 && getDateForLable(showStickyNotes * 1000) !== 'Today'" style="width: 100%; position: relative; z-index: 1;">
                <div style="position: absolute; top: 10px; left: 48.4%; border-radius: 50px; border: 1px solid #898989; background: white; padding: 2px 10px; transform: translateX(-50%); font-size: 12px;">
                    {{getDateForLable(showStickyNotes * 1000)}}
                </div>
            </div> -->
            <div class="scrollChatBox chat_message_box chatbox" id="Chatbox" @scroll="myFunc" v-if="chatArr.length">
                <!-- <b-img
                  :src="require('../../../assets/images/erflog/DownArrow.png')"
                  alt="avatar"
                  class="chatboxdownarrow"
                  v-if="showArrow"
                  @click="ChatMessageDown"
                /> -->
                <feather-icon
                  icon="ChevronDownIcon"
                  size="16"
                  class="chatboxdownarrow"
                    v-if="showArrow"
                  @click="ChatMessageOnArrowClick"
                />
                <div v-for="(data,index) in chatArr" :key="index" :id="data.id" :ref="data.id">
                    <template v-if="chatArr[index - 1]"> 
                        <div v-if="index >= 1 && new Date(chatArr[index-1].createdAt.seconds * 1000).toISOString().split('T')[0] !== new Date(chatArr[index].createdAt.seconds * 1000).toISOString().split('T')[0]" class="messageTimeDiffShow">
                            <p style="text-align: center !important;background-color: #f8f8f8;" v-if="new Date(chatArr[index].createdAt.seconds * 1000).toISOString().split('T')[0] == new Date().toISOString().split('T')[0]">{{getDateForLable(chatArr[index].createdAt.seconds * 1000)}}</p>
                            <p style="text-align: center !important;background-color: #f8f8f8;" v-else>{{getDateForLable(chatArr[index].createdAt.seconds * 1000)}}</p>
                        </div>
                    </template>
                    <template v-else>
                        <div class="messageTimeDiffShow">
                            <p style="text-align: center !important;background-color: #f8f8f8;">{{getDateForLable(chatArr[index].createdAt.seconds * 1000)}}</p>
                        </div>
                    </template>
                    <div :class=data.class>
                        <div class="chat_image d-inline-block align-top" v-if="showUserInfo(data, index)">
                            <!-- <span class="b-avatar badge-minimal badge-light-primary rounded-circle" style="width: 40px; height: 40px"> -->
                            <span class="b-avatar badge-minimal badge-light-primary rounded-circle" style="display: block;width: 30px;height: 30px;background: #E6E7ED;border-radius: 45px;object-fit: cover;-moz-border-radius: 50px;">
                                <span class="b-avatar-img">
                                    <!-- <b-img v-if="data.user.avatar" :src="data.user.avatar" alt="avatar" /> -->
                                    <b-img v-if="data.user.avatar" :src="data.isAdmin == true ? 'https://firebasestorage.googleapis.com/v0/b/erfolg-16de9.appspot.com/o/appIconErf.png?alt=media&token=602ffc45-b9eb-44e7-a4fd-e7cdecbe3cb3':data.user.avatar" alt="avatar" />
                                    <b-img v-else :src="require('@/assets/images/avatars/defaultUserProfile.jpg')" alt="avatar"/>
                                  </span>
                            </span>
                        </div>
                        <div style="width:30px;" v-else></div>
                        <div class="chat_detail d-inline-block align-top">
                            <div>
                                <span style="font-weight: 650;padding-right: 8px;" v-if="showUserInfo(data, index)"> {{ data.user.name }}</span>
                                <!-- <span style="font-weight: 650;padding-right: 8px;"> {{ data.user.name }}</span> -->
                                <span style="padding-right: 10px;"> {{ data.time }}</span>
                                <b-dropdown
                                    v-if="!onlyRead"
                                    variant="link"
                                    toggle-class="text-decoration-none"
                                    class="dropdownClassWrp"
                                    :disabled="onlyRead"
                                    no-caret
                                >
                                    <template v-slot:button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="text-body align-middle"
                                    />
                                    </template>
                                    <b-dropdown-item @click="$bvModal.show(`modal-remove_message`),messageIdDelete = data.id">
                                        <span>
                                            <b-img src="../../../assets/images/erflog/Delete.png" style="margin-right: 8px;"></b-img>
                                            Delete
                                        </span>
                                    </b-dropdown-item>
                                    <!-- <b-dropdown-item v-if="data.class == 'left'" @click="$bvModal.show(`modal-remove_Block`),blockUserId = data"> -->
                                    <b-dropdown-item v-if="!data.isCommunityChatBlock && data.class=='left'" @click="$bvModal.show(`modal-remove_Block`),blockUserId = data">
                                    <!-- <b-dropdown-item v-if="!data.isCommunityChatBlock" @click="$bvModal.show(`modal-remove_Block`),blockUserId = data"> -->
                                        <span>
                                            <b-img src="../../../assets/images/erflog/Blocked-PNG-Image.png" style="margin-right: 8px;height: 17px;width: 17px;"></b-img>
                                            Block
                                        </span>
                                    </b-dropdown-item>
                                    <b-dropdown-item v-if="data.isCommunityChatBlock && data.class=='left'" @click="$bvModal.show(`modal-remove_Unblock`),unblockUserId = data">
                                    <!-- <b-dropdown-item v-if="data.isCommunityChatBlock" @click="$bvModal.show(`modal-remove_Unblock`),unblockUserId = data"> -->
                                        <span>
                                            <b-img src="../../../assets/images/erflog/unblock_icon.png" style="margin-right: 8px;height: 19px;width: 19px;"></b-img>
                                            Unblock
                                        </span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="message.replyMessage = {...data},isReply = true,focus()">
                                    <!-- <b-dropdown-item v-if="data.isCommunityChatBlock" @click="$bvModal.show(`modal-remove_Unblock`),unblockUserId = data"> -->
                                        <span>
                                            <!-- <b-img src="../../../assets/images/erflog/Blocked-PNG-Image.png" style="margin-right: 8px;height: 17px;width: 17px;"></b-img> -->
                                            <feather-icon icon="CornerUpLeftIcon" style="margin-right: 8px;height: 17px;width: 17px;" />
                                            Reply
                                        </span>
                                    </b-dropdown-item>
                                    </b-dropdown>
                                <!-- <span><b-img src="../../../assets/images/erflog/kabab_icon_erf.png" style="cursor: pointer;height: 26px;width: 33px;" @click="deleteMessageHandler(data.id)"></b-img></span> :class="[{'paragraph-hidden-show': data.showLargeMessage === false}]" -->
                            </div>
                            <div style="margin-top: 4px;">
                                <!-- <span class="BoxShadow" :id="data.id+'_message'" style="font-size: 14px;color: #000;" v-if="!data.isReply" >{{ data.showLargeMessage === false ? data.text.substring(0, 465) : data.text }}<span v-if="data.showLargeMessage !== undefined" class="read-more-button" @click="data.showLargeMessage = !data.showLargeMessage">... Read {{!data.showLargeMessage ? 'more' : 'less'}}</span></span> -->
                                <span class="BoxShadow" :id="data.id+'_message'" style="font-size: 14px;color: #000;" v-if="!data.isReply" v-html="data.text" v-linkified></span>
                                <div class="replyMessageContent" style="position: relative;" v-else>
                                    <span class="BoxShadow" :id="data.id+'_message'">
                                        <img style="position: absolute;top: 16px;" src="../../../assets/images/svg/repalyarrow.svg" alt="">
                                        <div style="padding-left: 28px;">
                                            <span class="d-flex align-items-start repalyMeassageText">
                                                <span class="d-flex" style="padding-right: 15px;">
                                                    <!-- <img :src="data.replyMessage.user.avatar ? data.replyMessage.user.avatar : require('@/assets/images/avatars/defaultUserProfile.jpg')" class="replayUserImg"> -->
                                                    <img :src="(data.replyMessage.isAdmin !== true) ? (data.replyMessage.user.avatar ? data.replyMessage.user.avatar : require('@/assets/images/avatars/defaultUserProfile.jpg')) : 'https://firebasestorage.googleapis.com/v0/b/erfolg-16de9.appspot.com/o/appIconErf.png?alt=media&token=602ffc45-b9eb-44e7-a4fd-e7cdecbe3cb3'" class="replayUserImg">
                                                    <a style="text-decoration:none;cursor: default;"><pre @click="highlightMessage(data.replyMessage)" style="margin-bottom: 10px !important;cursor: pointer;background-color: transparent;" v-html="`<strong>${data.replyMessage.user.name}`"></pre></a>
                                                </span>
                                                <span style="max-width: 295px;">
                                                    <pre style="margin-bottom: 10px !important;background-color: transparent;" class="newMessageText" >{{ data.replyMessage.text }}
                                                    </pre>
                                                </span>
                                            </span>
                                            <!-- <span style="font-size: 14px;color: #000;">{{ data.showLargeMessage === false ? data.text.substring(0, 465) : data.text }}<span v-if="data.showLargeMessage !== undefined" class="read-more-button" @click="data.showLargeMessage = !data.showLargeMessage">... Read {{!data.showLargeMessage ? 'more' : 'less'}}</span></span> -->
                                            <span style="font-size: 14px;color: #000;" v-html="data.text" v-linkified></span>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="chat_message_box chatbox" v-else>
               <p class="centerDivText">No Data Found</p>
            </div> -->
            <div class="chat-footer chatboard" v-if="!onlyRead">
                <div class="repalyingTo d-flex" v-if="Object.keys(message.replyMessage).length">
                    <div>
                        <strong>@{{ message.replyMessage.user.name == "Erfolg Counsel" ? 'You' : message.replyMessage.user.name }}</strong> - <span class="replayingMsg">{{message.replyMessage.text}}</span>
                    </div>
                    <div>
                        <span>
                            <img src="../../../assets/images/erflog/Close_Red.png" class="closeReply" @click="message.replyMessage = {}">
                        </span>
                    </div>
                </div>
                <b-form @submit.prevent class="invite_friends_form">
                    <b-form-group class="d-inline-block align-middle message-group">
                        <b-form-input ref="foo" v-model.trim="message.message" id="mc-message" maxlength=250 placeholder="Type here..."  :disabled="onlyRead"/>
                    </b-form-group>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        :disabled="onlyRead"
                        variant="primary"
                        class="d-inline-block align-middle"
                        @click.prevent="MsgSent"
                        style="margin-left: 40px;"
                    >
                    SEND
                    </b-button>
                </b-form>
            </div>
        </div>
        <b-modal
            id="modal-remove_message"
            ok-title="Yes"
            cancel-title="No"
            centered
            @ok="deleteMessageHandler"
            @cancel="cancelModelHandler"
            hide-header
        >
        <div class="create_account_img">
            <img src="../../../assets/images/erflog/DeletePopup.png" alt="">
        </div>
        <div style="text-align: center;">
            <h3>Are you sure you want to delete message?</h3>
        </div>
        </b-modal>
        <b-modal
            id="modal-remove_Block"
            ok-title="Yes"
            cancel-title="No"
            centered
            @ok="blockUserHandler"
            @cancel="cancelHandler"
            hide-header
        >
        <div class="create_account_img">
            <img src="../../../assets/images/erflog/Approve_Popup.png" alt="">
        </div>
        <div style="text-align: center;">
            <h3>Are you sure you want to block this user?</h3>
        </div>
        </b-modal>
        <b-modal
            id="modal-remove_Unblock"
            ok-title="Yes"
            cancel-title="No"
            centered
            @ok="unblockUserHandler"
            @cancel="cancelHandlerUnbock"
            hide-header
        >
        <div class="create_account_img">
            <img src="../../../assets/images/erflog/Approve_Popup.png" alt="">
        </div>
        <div style="text-align: center;">
            <h3>Are you sure you want to unblock this user?</h3>
        </div>
        </b-modal>
    </div>
</template>
<script>
import { BCard,BImg,BFormInput,BFormGroup,BForm,BButton,BSpinner,BDropdownItem,BDropdown } from 'bootstrap-vue';
import { dbCollections } from '@/utils/firebaseCollection';
import firebaseApp from '@/utils/firebaseInit';
import moment from 'moment';
const db = firebaseApp.firestore();
import firebaseTimeStamp from "firebase";
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { mapActions,mapGetters,mapMutations } from 'vuex';
import axios from 'axios';
import { setTimeout } from 'timers';

export default {
    directives: {
        Ripple,
    },
    name:"community",
    components:{
        BCard,
        BImg,
        BFormGroup,
        BFormInput,
        BButton,
        BForm,
        BSpinner,
        BDropdownItem,
        BDropdown
    },
    data(){
        return{
            currentUser:null,
            lastVisible:null,
            chatArr:[],
            msg:'',
            message:{
                replyMessage:{},
                message:"",
            },
            isSpinner:false,
            functionCall: false,
            scrollNumber: 0,
            showArrow:false,
            lastDocId:'',
            messageLimit:20,
            chatOpened:false,
            visible:false,
            messageIdDelete:'',
            blockUserId:null,
            unblockUserId:null,
            studentList:[],
            onlyRead:false,
            subscribeSnap:null,
            messageReply:null,
            isReply:false,
            // showStickyNotes:0
        }
    },
    created() {
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'))
        this.isSpinner = true;
        let usersFound = this.getUsersList.length!=0;
        let userAccess = JSON.parse(localStorage.getItem('userAccess'));
        if(userAccess) {
            let index = userAccess.findIndex((ele)=>{
                return ele.name === "Community"
            })
            if(index !== -1) {
                if(userAccess[index].allow.toLowerCase() == 'read') {
                    this.onlyRead = true;
                }
            }
        }
        if(!usersFound) {
            this.getUsers()
            .then(()=>{
                this.getUsersList.forEach((data)=>{
                    if(data.role == "student") {
                        this.studentList.push(data.id)
                    }
                })
            })
            .catch(error=>{
                console.error('error',error);
            });
        } 
        else {
            this.getUsersList.forEach((data)=>{
                if(data.role == "student") {
                    this.studentList.push(data.id)
                }
            })
        }
        this.getArray().then(()=>{
            this.ChatInitial();

            this.isSpinner = false;
        }).catch(()=>{
            this.isSpinner = false;
        })
    },
    // mounted() {
    //     let self = this;
    //     setTimeout(()=>{
    //         console.log('document.getElementsByClassName("chatbox")[0]',document.getElementsByClassName('chatbox')[0])
    //         document.getElementsByClassName("chatbox")[0].addEventListener("scroll", () => {
    //             if(self.chatArr.length) {
    //                 let timed = self.chatArr.filter((x) => new Date(x.createdAt.seconds * 1000).toISOString().split('T')[0] !== new Date().toISOString().split('T')[0]).map((x) => x.id);
    //                 console.log(timed,'TIMESSSSSSSSS')
    //                 if(timed.length) {
    //                     timed = timed.map((x) => ({id: x, top: document.getElementById(x).getBoundingClientRect().top})).filter((x) => x.top - 200 >= 0);
    //                     if(timed.length) {
    //                         let msgId = timed[0].id
    
    //                         let ind = self.chatArr.findIndex((x) => x.id === msgId);
    
    //                         if(ind > 0) {
    //                             self.showStickyNotes = self.chatArr[ind - 1].createdAt.seconds || 0
    //                         } else {
    //                             self.showStickyNotes = 0;
    //                         }
    //                     } else {
    //                         self.showStickyNotes = 0;
    //                     }
    //                 } else {
    //                     self.showStickyNotes = self.chatArr[self.chatArr.length - 1].createdAt.seconds;
    //                 }
    //             } else {
    //                 self.showStickyNotes = 0;
    //             }
    //         })
    //     },5000)
    // },
    beforeDestroy() {
		if(this.subscribeSnap !== null) {
            console.log("Snapshot Detached!");
			this.subscribeSnap();
		}
    },
    methods:{
        ...mapActions({
            'getUsers':'userCollection/getUsersData',
        }),
        ...mapMutations({
            'updateVaLUE' : "userCollection/updateArrayValue",
        }),
        getArray() {
            let self = this;
            if(self.subscribeSnap !== null) {
				console.info("Snapshot Detached!");
                self.subscribeSnap();
            }
            return new Promise((res,rej)=>{
            this.subscribeSnap = db.collection(dbCollections.COMMUNITY).orderBy('createdAt','desc').limit(this.messageLimit).onSnapshot({includeMetadataChanges: true},(snapshot) => {
                if(snapshot.empty) {
                    this.isSpinner = false;
                    return;
                }
                snapshot.docChanges({includeMetadataChanges: true}).forEach((change) => {
                    if(change.type === "added" && !change.doc.data().isDeleted) {
                        let index = self.chatArr.findIndex((ele)=>{
                            return ele.id == change.doc.data().id
                        })
                        if(index > -1) {
                            let obj = {...change.doc.data()};
                            obj.class = obj.user._id == this.currentUser.id || obj.isAdmin == true ? 'right' : 'left';
                            // obj.time = new Date(obj.createdAt.seconds * 1000).toISOString().split( "T" )[0] == new Date().toISOString().split( "T" )[0] ? `Today ${moment(obj.createdAt * 1000).format("hh:mm a")}` : `${moment(obj.createdAt.seconds * 1000).format("llll")}`
                            obj.time = this.getDateForLableWithtime(obj.createdAt.seconds * 1000)
                            self.chatArr[index] = obj
                        } else {
                            if(!change.doc.metadata.hasPendingWrites) {
                                if(this.chatArr.length == 0) {
                                    self.lastVisible = snapshot.docs[snapshot.docs.length-1];
                                    // self.lastDocId = snapshot.docs[0].id;
                                }
                                let obj = {...change.doc.data()};
                                obj.class = obj.user._id == this.currentUser.id || obj.isAdmin == true ? 'right' : 'left';
                                // obj.time = new Date(obj.createdAt.seconds * 1000).toISOString().split( "T" )[0] == new Date().toISOString().split( "T" )[0] ? `Today ${moment(obj.createdAt * 1000).format("hh:mm a")}` : `${moment(obj.createdAt.seconds * 1000).format("llll")}`
                                obj.time = this.getDateForLableWithtime(obj.createdAt.seconds * 1000)
                                self.chatArr.push(obj);
                                // this.ChatMessageDown();
                            }
                            // else {
                            //     let obj = {...change.doc.data()};
                            //     obj.class = obj.user._id == this.currentUser.id || obj.isAdmin == true ? 'right' : 'left';
                            //     obj.time = this.getDateForLableWithtime(obj.createdAt.seconds * 1000)
                            //     self.chatArr.push(obj);
                            //     this.ChatMessageDown();
                            // }
                        }
                    }
                    if(change.type === "modified") {
                        let index = self.chatArr.findIndex((x) => x.id === change.doc.id);
                        if(index > -1) {
                            if(change.doc.data().isDeleted) {
                                self.chatArr.splice(index, 1);
                            } else {
                                let obj = {...change.doc.data()}
                                obj.class = obj.user._id == this.currentUser.id || obj.isAdmin == true ? 'right' : 'left';
                                obj.time = this.getDateForLableWithtime(obj.createdAt.seconds * 1000)
                                self.chatArr[index] = {...obj};
                            }
                        } 
                        else {
                            if(!change.doc.data().isDeleted) {
                                let obj = {...change.doc.data()};
                                obj.class = obj.user._id == this.currentUser.id || obj.isAdmin == true ? 'right' : 'left';
                                obj.time = this.getDateForLableWithtime(obj.createdAt.seconds * 1000)
                                self.chatArr.push({...obj});
                                this.ChatMessageDown();
                            }
                        }
                    }
                    if(change.type === "removed" && !change.doc.metadata.hasPendingWrites) {
                        let index = self.chatArr.findIndex((x) => x.id === change.doc.id);
                        if(index > -1) {
                            self.chatArr.splice(index, 1);
                            if (self.lastDocId == this.messageIdDelete || self.lastDocId == change.doc.data().id) {
                                self.lastDocId = self.chatArr[index].id
                            }
                        } else {
                            if (self.lastDocId == this.messageIdDelete) {
                                self.lastDocId = self.chatArr[index + 1].id
                            }
                        }
                    }
                    // if(index + 1 == snapshot.docs.length) {
                    //     this.updateFunction(this.chatArr).then(()=>{})
                    // }
                })
                this.updateFunction(this.chatArr).then(()=>{
                    if(self.chatArr.length) {
                        self.chatArr.sort((book1, book2) => {
                            return self.compareObjects(book1, book2, 'createdAt')
                        });
                        self.$nextTick(()=>{
                            // setTimeout(()=>{
                            res('FOUND')
                            // },1000)
                            // this.$forceUpdate();
                        })
                    } else {
                        this.isSpinner = false;
                    }
                })

                // this.ChatMessageDown();
            })
        })
        },
        getDateForLable(seconds) {
            return moment(new Date(seconds)).calendar({
                sameDay: '[Today]',
                nextDay: 'DD/MM/YYYY',
                nextWeek: 'DD/MM/YYYY',
                lastDay: '[Yesterday]',
                lastWeek: 'DD/MM/YYYY',
                sameElse: 'DD/MM/YYYY',
            });
        },
        getDateForLableWithtime(seconds) {
            return moment(new Date(seconds)).calendar({
                sameDay: 'hh:mm a',
                nextDay: 'llll',
                nextWeek: 'llll',
                lastDay: '[Yesterday] hh:mm a',
                lastWeek: 'llll',
                sameElse: 'llll',
            });
        },
		compareObjects(object1, object2, key) {
            const obj1 = object1[key].seconds;
            const obj2 = object2[key].seconds;
            if (obj1 <= obj2) {
              return -1
            }
            if (obj1 > obj2) {
              return 1
            }
            return 0
		},
        scrollBottom(){
            let element = document.getElementsByClassName("scrollChatBox");
            if(this.chatArr.length) {
                element.scrollTo(0, element.scrollHeight);
            }
        },
        async MsgSent() {
            try {
                if (this.message.message !== "") {
                    let chatMessage = {...this.message}
                    this.message = {
                            replyMessage:{},
                            message:"",
                        };
                        // createdAt:firebaseTimeStamp.firestore.Timestamp.fromDate(new Date()),
                        const chatObject = {
                            createdAt:firebaseTimeStamp.firestore.Timestamp.fromDate(new Date()),
                            isAdmin:true,
                            isReply:Object.keys(chatMessage.replyMessage).length ? true : false,
                            replyMessage:Object.keys(chatMessage.replyMessage).length ? chatMessage.replyMessage : {},
                            text:chatMessage.message,
                            user:{
                                _id:this.currentUser.id,
                                avatar:'https://firebasestorage.googleapis.com/v0/b/erfolg-16de9.appspot.com/o/appIconErf.png?alt=media&token=602ffc45-b9eb-44e7-a4fd-e7cdecbe3cb3',
                                name:'Erfolg Counsel'
                            },
                            isDeleted:false
                        };
                        if(Object.keys(chatObject.replyMessage).length == 0){
                            delete chatObject.replyMessage;
                        } else {
                            if(chatObject.replyMessage.replyMessage && Object.keys(chatObject.replyMessage.replyMessage).length) {
                                delete chatObject.replyMessage.replyMessage;
                            }
                            delete chatObject.replyMessage.class;
                            delete chatObject.replyMessage.time;
                        }
                        let doc = db.collection(dbCollections.COMMUNITY).doc();
                        chatObject.id = doc.id;
                        doc.set(chatObject).then(()=>{
                            let div = document.getElementById("Chatbox");
                            if(this.chatArr.length > 1) {
                                div.scrollTop = div.scrollHeight;
                            }
                            let object = {
                                "userIds": this.studentList,
                                "message": chatMessage.message
                                };
                            axios.post(dbCollections.APIURL+'api/v1/communityChatNotification',object,(res)=>{
                                if(res.data.status) {
                                    console.warn('NOTIFICATION CALLED')
                                } else {
                                    console.warn('NOTIFICATION NOT CALLED')
                                }
                            }).catch((err)=>{
                                console.error(err)
                            })
                        })
                } else {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: 'Message is required.',
                            icon: 'CoffeeIcon',
                            variant: 'danger',                               
                        },
                    });
                }
            } catch (error) {
                console.error(error);
            }
        },
        ChatInitial() {
            if (this.functionCall === false) {
                setTimeout(() => {
                this.functionCall = true;
                let div = document.getElementById("Chatbox");
                div.scrollTop = div.scrollHeight;
                this.scrollNumber = div.scrollTop;
                }, 100);
            }
        },
        ChatMessageDown() {
            if (this.functionCall === true && this.showArrow == false) {
                setTimeout(() => {
                    let div = document.getElementById("Chatbox");
                    div.scrollTop = div.scrollHeight;
                }, 100);
            }
        },
        ChatMessageOnArrowClick() {
            if (this.functionCall === true) {
                setTimeout(() => {
                    let div = document.getElementById("Chatbox");
                    div.scrollTop = div.scrollHeight;
                }, 100);
            }
        },
        async deleteMessageHandler(){
			var self = this;
            //isDeleted Update Query
			await db.collection(dbCollections.COMMUNITY).doc(this.messageIdDelete)
				.update({isDeleted: true})
                .then(async()=>{
                    //Delete chat doc
                    db.collection(dbCollections.COMMUNITY).doc(this.messageIdDelete).delete();
                    let index = self.chatArr.findIndex((ele)=>ele.id == this.messageIdDelete)
                    if(index != -1) {
                        self.chatArr.splice(index,1)
                        if (self.lastDocId == this.messageIdDelete) {
                            self.lastDocId = self.chatArr[index].id
                        }
                    }
                    // this.messageIdDelete = '';
                })
				.catch((error) => {
                    // this.messageIdDelete = '';
					console.error("ERROR in message delete: ", error);
				})
		},
        //User Block Query
        async blockUserHandler(){
			try {
                var self = this;
                this.isSpinner = true;
                await db.collection(dbCollections.USERS).doc(this.blockUserId.user._id)
                .update({isCommunityChatBlock: true,'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()})
                .then(()=>{
                        self.chatArr.forEach((ele,index)=>{
                            if(ele.user._id == this.blockUserId.user._id) {
                                let fincindex = this.getUsersList.findIndex((nObj)=>{
                                    return nObj.id == this.blockUserId.user._id
                                })
                                self.chatArr[index] = {...self.chatArr[index],isCommunityChatBlock:!self.chatArr[index].isCommunityChatBlock}
                                this.updateVaLUE({lineNu:fincindex,data:{...this.getUsersList[fincindex],isCommunityChatBlock:self.chatArr[index].isCommunityChatBlock}});
                            }
                        })
                        this.$root.$emit('showToastMessage','User blocked successfully.','success');
                        this.$forceUpdate();
                        this.blockUserId = null;
                        this.isSpinner = false;
                        self.ChatMessageOnArrowClick()
                        // let div = document.getElementById("Chatbox");
                        // div.scrollTop = div.scrollHeight;
                    })
                    .catch((error) => {
                        this.blockUserId = null;
                        this.$root.$emit('showToastMessage','Some thing went wronge','danger');
                        this.isSpinner = false;
                        console.error("ERROR in message BLCCK USER:: ", error);
                    })
            } catch (error) {
                this.blockUserId = null;
                this.isSpinner = false;
                console.error(error)
            }
		},
        async unblockUserHandler(){
			var self = this;
            try {
                this.isSpinner = true;
                await db.collection(dbCollections.USERS).doc(this.unblockUserId.user._id)
                .update({isCommunityChatBlock: false,'updatedAt':firebaseTimeStamp.firestore.FieldValue.serverTimestamp()})
                .then(()=>{
                        self.chatArr.forEach((ele,index)=>{
                            if(ele.user._id == this.unblockUserId.user._id) {
                                let fincindex = this.getUsersList.findIndex((nObj)=>{
                                    return nObj.id == this.unblockUserId.user._id
                                })
                                self.chatArr[index] = {...self.chatArr[index],isCommunityChatBlock:!self.chatArr[index].isCommunityChatBlock}
                                this.updateVaLUE({lineNu:fincindex,data:{...this.getUsersList[fincindex],isCommunityChatBlock:self.chatArr[index].isCommunityChatBlock}});
                            }
                        })
                        this.$forceUpdate();
                        this.$root.$emit('showToastMessage','User unblocked successfully.','success');
                        this.unblockUserId = null;
                        this.isSpinner = false;
                        self.ChatMessageOnArrowClick()
                        // let div = document.getElementById("Chatbox");
                        // div.scrollTop = div.scrollHeight;
                    })
                    .catch((error) => {
                        this.unblockUserId = null;
                        this.$root.$emit('showToastMessage','Some thing went wronge','danger');
                        this.isSpinner = false;
                        console.error("ERROR in message UNBLCCK USER: ", error);
                    })
            } catch (error) {
                this.unblockUserId = null;
                this.isSpinner = false;
                console.error(error)
            }
		},
        cancelHandlerUnbock() {
            this.unblockUserId = null
            this.$bvModal.hide("modal-remove_Unblock")
        },
        myFunc(e) {
            try {
                var self=this;
                // self.showArrow = ((e.target.scrollTop + 712) < (e.target.scrollHeight - 300)) || false;
                // if (e.target.scrollTop === 0) {
                //     // console.log("Data is 0");
                //     self.showArrow = false;
                // } else if (e.target.scrollHeight - e.target.scrollTop > 650) {
                //     // console.log("1>>>>>>>>>", e.target.scrollHeight - e.target.scrollTop);
                //     self.showArrow = true;
                // } else {
                //     self.showArrow = false;
                // }
                // const prevMsgId = JSON.parse(JSON.stringify(self.chatArr[self.chatArr.length-1].id));
                if (e.target.scrollTop === 0) {
                    if (self.lastVisible !== null) {
                        db.collection(dbCollections.COMMUNITY).where("isDeleted",'==',false).orderBy("createdAt","desc").startAfter(self.lastVisible).limit(this.messageLimit).get().then((querySnapshot) => {
                            if(querySnapshot.empty) {
                                self.lastVisible = null;
                                self.lastDocId = '';
                                // self.showArrow = true;
                                return;
                            }
                            let count = 0;
                            const loopScroll = async (doc) => {
                                if(querySnapshot.docs.length <= count) {
                                    // if(self.lastDocId !== '') {
                                        setTimeout(()=>{
                                            self.lastDocId = querySnapshot.docs[querySnapshot.docs.length -1].data().id
                                            document.getElementById(self.lastDocId).scrollIntoView({behavior: 'smooth'});
                                        },100)
                                        // this.$refs.this.lastDocId.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
                                    // }
                                    await this.updateFunction(self.chatArr).then(()=>{
                                        // console.log(self.chatArr,'BEFORE')
                                        // self.chatArr.sort((book1, book2) => {
                                        //     return self.compareObjects(book1, book2, 'createdAt')
                                        // });
                                        // console.log(self.chatArr,'AFTER')
                                    })
                                    .catch((error)=>{
                                        console.error(error)
                                    })
                                    return;
                                }
                                this.lastVisible = doc;
                                if(self.chatArr.findIndex(x => x.id === doc.id) === -1) {
                                    let obj = {...doc.data()}
                                    obj.class = obj.user._id == self.currentUser.id || obj.isAdmin == true ? 'right' : 'left';
                                    obj.time = new Date(obj.createdAt.seconds * 1000).toISOString().split( "T" )[0] == new Date().toISOString().split( "T" )[0] ? this.getDateForLableWithtime(obj.createdAt.seconds * 1000) : this.getDateForLableWithtime(obj.createdAt.seconds * 1000)
                                    self.chatArr.unshift(obj);
                                }
                                count++
                                loopScroll(querySnapshot.docs[count])
                            }
                            loopScroll(querySnapshot.docs[count])
                        }).catch((error) => {
                            console.error("Scroll get message error: ", error);
                        })
                    } else {
                        console.warn("No data Found");
                    }
                }
                self.showArrow = ((e.target.scrollTop + 405) < (e.target.scrollHeight - 300)) || false;

            } catch (error) {
                console.error(error);
            }
        },
        cancelModelHandler() {
            this.messageIdDelete = '';
            this.$bvModal.hide(`modal-removeDoc`);
        },
        cancelHandler() {
            this.blockUserId = null;
            this.$bvModal.hide('modal-remove_Block')
        },
        updateFunction(array) {
            return new Promise ((res,rej)=>{
                let count = 0;
                const getUserFun = async (eleObject) => {
                    if(count >= array.length) {
                        if(array.length) {
                            res('FOUND')
                        } else {
                            this.isSpinner = false;
                        }
                        this.$forceUpdate();
                        return;
                    } else {
                        let ele;
                        ele = eleObject
                        let index = this.getUsersList.findIndex((eless)=>{
                            return eless.id == ele.user._id
                        })
                        if(index !== -1) {
                            array[count].isCommunityChatBlock = this.getUsersList[index]?.isCommunityChatBlock !== undefined && this.getUsersList[index]?.isCommunityChatBlock !== null ? this.getUsersList[index].isCommunityChatBlock : false;
                            // this.getUsersList[index].isCommunityChatBlock = this.getUsersList[index]?.isCommunityChatBlock !== undefined && this.getUsersList[index]?.isCommunityChatBlock !== null ? this.getUsersList[index].isCommunityChatBlock : false;
                            this.updateVaLUE({lineNu:index,data:{...this.getUsersList[index],isCommunityChatBlock:array[count].isCommunityChatBlock}});
                            count++;
                            getUserFun(array[count]);
                        } else  {
                            count++;
                            getUserFun(array[count]);
                        }
                    }
                }
                getUserFun(array[count]);
            })
        },
        sameTime(data, data2){
            return data && new Date(data.createdAt.seconds * 1000).setSeconds(0) && new Date(data.createdAt.seconds * 1000).setSeconds(0) === new Date(data2.createdAt.seconds * 1000).setSeconds(0);
        },
        sameUser(data, data2){
            return data && data.user._id && data.user._id === data2.user._id;
        },
        showUserInfo(data, index) {
            let show = true;
            if(data.class == 'right') {
                show = true;
            } else if(data.class !== 'right' && this.chatArr[index-1]) {
                if(this.sameTime(this.chatArr[index-1], data) && this.sameUser(this.chatArr[index-1], data)) {
                    show = false;
                }
            }
            return show;
        },
        highlightMessage(data){
            let element = document.getElementById(data.id + '_message');
            console.log(element,'ELEMENT')
            if(element !== undefined && element !== null) {
                console.log('QUERY not CALLED')
                element.scrollIntoView()
                element.classList.toggle("highlighted-message");
                setTimeout(()=>{
                    element.classList.toggle("highlighted-message");
                },2000)
            } else {
                console.log('QUERY CALLED')
                this.getPreviousData(data)
                .then((res)=>{
                    let element = document.getElementById(data.id + '_message');
                    console.log(element)
                    if(element !== null && res) {
                        element.scrollIntoView()
                        console.log(element,'element')
                        element.classList.toggle("highlighted-message");
                        setTimeout(()=>{
                            element.classList.toggle("highlighted-message");
                        },2000)
                    }
                });
            }
        },
        focus(){
            setTimeout(()=>{
                this.$refs.foo.focus()
            },1)
		},
        getPreviousData(data){
            var self = this;
            // console.log(data,'data',self.lastVisible.data().createdAt);
            let endTime = new Date(data.createdAt.seconds * 1000);
            let dateAfter = new Date(self.lastVisible.data().createdAt.seconds * 1000);
            // console.log(endTime,'endTime=============')
            // console.log(dateAfter,'dateAfter--------------------')
			return new Promise((resolve, reject) => {
				db.collection(dbCollections.COMMUNITY).where("isDeleted","==", false).orderBy('createdAt','desc').startAfter(dateAfter).endAt(endTime).get().then((querySnapshot) => {
                    console.log('QUESRYRESULT',querySnapshot)
					if(querySnapshot.empty) {
						resolve(false);
						return;
					}
					querySnapshot.forEach((doc) => {
						if(!doc.data().isDeleted) {
                            // console.log('DOC_------------',doc.data().text,index,querySnapshot.docs.length)
							self.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
                            self.lastDocId = querySnapshot.docs[querySnapshot.docs.length - 1].id;
                            if(self.chatArr.findIndex(x => x.id === doc.id) === -1) {
                                let obj = {...doc.data()}
                                obj.class = obj.user._id == self.currentUser.id || obj.isAdmin == true ? 'right' : 'left';
                                obj.time = new Date(obj.createdAt.seconds * 1000).toISOString().split( "T" )[0] == new Date().toISOString().split( "T" )[0] ? this.getDateForLableWithtime(obj.createdAt.seconds * 1000) : this.getDateForLableWithtime(obj.createdAt.seconds * 1000)
                                self.chatArr.unshift(obj);
                            }
						}
					})
                    // console.log('DATAFOUND')
                    resolve(true);
				}).catch((error) => {
					reject(error);
				})
			})
        },
    },
    computed:{
        ...mapGetters({
            getUsersList:'userCollection/getUsers',
        })
    }
}
</script>
<style scoped>
.chatbox {
  position: relative;
}
.left{
    display: flex;
}
.chat_message_box .left .chat_detail{
    margin-left: 22px;
    max-width: 483px;
}
.chat_message_box .right .chat_detail{
    margin-right: 22px;
    max-width: 483px;
}
.chat_bottom_part button.btn {
    width: auto;
    padding: 16.5px 30px;
    margin-left: 15px;
}
.chat_bottom_part {
    background-color: #e2e2e2;
    padding: 15px 30px;
}
.chat_message_box .left{
    margin-bottom: 15px;
}
.chat_message_box {
    padding: 33px 30px 0px;
    margin-bottom: 5PX;
    max-height: calc(100vh - 270px);
    width: 100%;
    overflow: auto;
}
.chat_message_box .right{
    text-align: right;
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 15px;
}
.chat-footer form.invite_friends_form {
    /* width: 100%; */
    width: 100%;
    /* margin: 19px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-footer form.invite_friends_form .message-group {
    /* display: flex!important; */
    margin-bottom: 0;
    width: 92%;
}
.chat_detail .card {
    margin-bottom: 2px;
    border: 18px;
}

.chat_detail .card .card-body {
    padding: 10px 10px;
}

.chatboxdownarrow {
    cursor: pointer;
    height: 22px;
    margin-top: -8px;
    position: sticky;
    top: 0px;
    z-index: 1;
    left: 90%;
    border-radius: 50%;
    transform: scaleX(0.9) scaleY(1);
    opacity: 1;
    transition: box-shadow 0.08s cubic-bezier(0.4, 0, 0.2, 1);
    background-color: #b8c2cc;
    box-shadow: 0px 0px #ebe9f1;
    width: 2%;
}
.centerDivText {
    text-align: center;
    position: absolute;
    top: 50%;left: 50%;
    transform: translateX(-50%) translateY(-50%);
    font-size: 24px;
    font-weight: 500;
}
.chat_message_box::-webkit-scrollbar{
    display: none;
  }
.BoxShadow {
    box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
    background-color: white;
    padding: 10px;
    word-break: break-all;
    display: block;
    width: fit-content;
    margin: 0 0 0 auto;
    border-radius: 8px;
    text-align: left;
}
.chat-footer.chatboard {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 0px 30px;
}
.chat_wrapper {
    position: relative;
    height: calc(100vh - 210px);
}
.dropdownClassWrp {
    place-self: flex-start
}
.left span.BoxShadow {
    margin: auto 0 0 0 !important;
}
.repalyingTo {
    justify-content: space-between;
    width: 91.8%;
    background-color: rgb(225 226 229);
    border-radius: 5px;
    padding: 0px 8px;
}
.closeReply {
    cursor: pointer;
}
.newMessageText{
    margin-bottom: 10px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 15px 0px 0px;
}
/* .paragraph-hidden-show {
    padding: 7px 20px 7px 20px;
} */
.read-more-button {
    cursor: pointer;
    word-break: break-word;
}
.messageTimeDiffShow {
    position: relative;
    height: 1px;
    background-color: #959595;
    margin: 20px 0 20px 0;
    width: 96.5%;
}
.messageTimeDiffShow p {
    position: absolute;
    top: -8px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: #f4f5f7;
    padding: 0 29px;
    color: #959595;
    line-height: 18.27px;
    font-size: 14px;
    font-weight: 500;
}
</style>